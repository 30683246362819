<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center mb-4">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}<br>{{desc3}}</p>
                        <p v-b-modal.modal-3 variant="link" class="mb-0"><u>판매량 모니터링 상품 추가 방법</u></p>
                        <div v-if="showspinner"><img style="max-width:10%;" :src="require('../../../assets/images/small/loading.gif')">{{loadingtext}}</div> 
                            <b-modal id="modal-3" size="xl" title="쿠팡 모니터링 상품 추가 방법" ok-only>
                                <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 쿠플러스에 로그인한 다음 <b style="color:red"><u>"쿠팡 상품페이지"에서</u></b> "링크수집(판매량)" 버튼을 클릭해주세요.<br> 한번 추가된 상품은 매일 1회 자동으로 수집됩니다.</p>
                                <img  style="max-width:100%;" :src="require('../../../assets/images/small/모니터링추가.png')">
                            </b-modal>
							<b-button variant="primary pl-5 pr-5" class="mt-2 mr-1" @click="collecttr">{{linktext}}</b-button>
							<b-button class="mt-2 mr-1" style="background-color:white;border-color:white" @click="startTimer" :disabled="timerRunning"><b-icon icon="play-btn" scale="2" variant="primary"></b-icon></b-button>
							<b-button class="mt-2 mr-1" style="background-color:white;border-color:white" @click="stopTimer" :disabled="!timerRunning"><b-icon icon="stop-btn" scale="2" variant="secondary"></b-icon></b-button>
							<div class="mt-2 mr-1" v-if="timerRunning">자동수집중(시작 : {{startTime}} / Count : {{timerCnt}} / 다음수집 : {{currentTime}})</div>
                    </div>
					
                </div>
			</div>

			<div class="col-lg-6 mt-3" v-if="showlist">
				<b-button variant="outline-success pl-5 pr-5" class="mt-2 mr-1 mb-2" v-b-toggle.my-collapse>판매량 보정</b-button>
				<b-collapse id="my-collapse">
					<b-card title="">
						보정치 적용 시 마이너스 판매량을 0으로 바꾸어 줍니다. (마이너스 임계치가 -2일경우 -2이하의 판매량은 모두 0으로 적용)
						<br>
						<br>
						[마이너스 임계치]
						<div class="form-group d-inline-block d-flex justify-content-between">
							<input type="number" class="form-control" placeholder="마이너스 임계치" v-model="minuslimit">

						</div>
						<div class="custom-control custom-switch custom-control-inline">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="applyminuslimit" @change="applycorrect">
								<label class="custom-control-label" for="customSwitch2" v-b-tooltip.bottom title="전체 페이지에서 검색하며, 여러개가 검색될 경우 페이지랭킹이 여러게로 나타납니다.">보정치 적용</label>
							</div>						
					</b-card>
				</b-collapse>      
			</div>

			<div class="col-lg-12 mt-3" v-if="showlist" style="font-size:80%">
				<div class="table-responsive rounded mb-3">
					<tableList>
						<template v-slot:header>
							<tr class="ligth ligth-data">
                                <th>
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="checkalllists" @change="checkalllist">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </th>
								<th>상품정보</th>
								<th>로켓구분</th>
								<th>로켓뱃지</th>
								<th>재고</th>
								<th>판매</th>
								<th>일평균판매량</th>
								<th>판매가</th>
								<th>판매매출</th>
								<th>리뷰수</th>
								<th>리뷰평점</th>
								<th>판매링크</th>
								<th>공급자</th>
							</tr>
						</template>
						<template v-slot:body>
							<tr v-for="(saleslist,index) in saleslists" :key="index">
								<td class="text-center">
                                    <div class="checkbox d-inline-block">
                                        <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="saleslist.checked">
                                        <label for="checkbox2" class="mb-0"></label>
                                    </div>
                                </td>
								<td v-b-modal="modalID(index)" style="min-width:100px;max-width:300px;overflow:hidden;text-overflow: ellipsis;">
									<div class="d-flex align-items-center">
                                        <img :src="saleslist.썸네일" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                        <div>
                                        {{saleslist.판매상품명}}
                                            <p class="mb-0"><small>VendorItemId: {{saleslist.vendorItemId}}</small></p>
                                        </div>
                                    </div>
								</td>
								<td v-b-modal="modalID(index)" style="width:60px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.로켓구분}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">
									<span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:100%">
								</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.재고}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.일평균판매량.toFixed(2)}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매가}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.판매매출}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.리뷰수}}</td>
								<td v-b-modal="modalID(index)" style="width:50px;overflow:hidden;text-overflow: ellipsis;">{{saleslist.리뷰평점}}</td>
								<td style="width:50px;overflow:hidden;text-overflow: ellipsis;" class="text-center"><a v-if="saleslist.판매링크" :href="saleslist.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
								<td style="width:50px;overflow:hidden;text-overflow: ellipsis;" class="text-center">
									<p>{{saleslist.공급자}}</p>
									<a v-if="saleslist.판매자링크" :href="saleslist.판매자링크" target="_blank"><b-icon icon="link" scale="2" variant="secondary"></b-icon></a>
								</td>
									
									<b-modal :id="'modal'+index" scrollable title="판매세부내역" ok-title="OK" cancel-title="Close" class="detail-modal"  style="font-size:80%">
										<div class="row">
											<div class="col-12">
												<card style="padding:10px;">										
													<div class="d-flex align-items-center" style="margin:auto;padding:0px">
														<img :src="saleslist.썸네일" class="img-fluid rounded avatar-120 mr-3" alt="image">
														<div>
														<p>판매상품명 : {{saleslist.판매상품명}}</p>
														<p>로켓구분 : {{saleslist.로켓구분}}</p>
														<p>로켓뱃지 : <span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:35%"></p>
														
                                                        <!-- <p class="mb-0"><small>ProductId: {{saleslist.productId}}</small></p> -->
														</div>
													</div>
												</card>
											</div>
											<div class="col-12">
												※ 최대 100줄로 표시되며 그 이상 수집하는 경우 오래된 순으로 자동 삭제됩니다.
												<div class="w-100 table-responsive" id="salestable">
													<table class="w-100 table data-table table-striped dataTable text-center" >
														<thead class="w-100">
															<tr class="ligth">
																
																<th>Date</th>
																<th>로켓뱃지</th>
																<th>재고</th>
																<th>판매</th>
																<th>판매가</th>
																<th>판매매출</th>
																<th>리뷰수</th>
																<th>리뷰평점</th>
																<th>판매링크</th>
															</tr>
														</thead>
														<tbody class="w-100">
															<tr v-for="(list,idx) in saleslist.판매세부내역" :key="idx">
																
																<td>{{list.date}}</td>
																<td>
																	<span v-if="!saleslist.로켓뱃지">없음</span><img v-if="saleslist.로켓뱃지" :src="saleslist.로켓뱃지" alt="image" style="max-width:100%">	
																</td>
																<td>{{list.재고}}</td>
																<td>{{list.판매}}</td>
																<td>{{list.판매가}}</td>
																<td>{{list.판매매출}}</td>
																<td>{{list.리뷰수}}</td>
																<td>{{list.리뷰평점}}</td>
																<td class="text-center"><a :href="saleslist.판매링크" target="_blank"><b-icon icon="link" scale="2" variant="primary"></b-icon></a></td>
															</tr>
															<tr>
																<td></td>
																<td></td>
																<td>일평균판매량</td>
																<td>{{saleslist.일평균판매량.toFixed(2)}}</td>
																<td>일평균매출</td>
																<td>{{saleslist.일평균매출.toFixed(0)}}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>
															<tr>
																<td></td>
																<td></td>
																<td>총판매량</td>
																<td>{{saleslist.판매세부내역.reduce((pv,cv) => {return pv + cv.판매},0)}}</td>
																<td>총매출</td>
																<td>{{saleslist.판매세부내역.reduce((pv,cv) => {return pv + cv.판매매출},0)}}</td>
																<td></td>
																<td></td>
																<td></td>
															</tr>															
														</tbody>
													</table>
												</div>   
												
											</div>   
										</div>  
                                    </b-modal>

							</tr>
						</template>
					</tableList>
				</div>
			
					<button type="button" class="btn btn-primary mr-2 mb-4" @click="downloadsales">다운로드</button>
					<button type="button" class="btn btn-success mr-2 mb-4" @click="salesdownload">상세다운로드</button>
					<button type="button" class="btn btn-secondary mr-2 mb-4" @click="deleteitem">삭제</button>
				
			

			</div>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
// import { splice } from 'core-js/internals/enum-bug-keys';
import * as XLSX from 'xlsx';
// import { json } from 'body-parser';
export default {
	name:'salesstatus',
	data(){
		return{
			timerRunning: false,
			timerInterval: null,
			startTime:'',
			timerCnt:0,
			timerTime:'',

			salesarr:[],
			importarr:[],
			stockarr:[],
			data:[],
			checkalllists:false,

			salescnt:0,
			importcnt:0,
			salesamount:0,
			supplyamount:0,
			noreview:0,
			productqty:0,
			nostock:0,
			// increview:0,
			// decreview:0,

			loadingtext:'데이터 불러오는 중 ...',


			title:"경쟁사 판매현황",
			desc1:"모니터링할 상품을 확장프로그램에서 먼저 추가해주세요. '판매현황수집'을 눌러 재고/판매량을 수집해주세요.",
			desc2:"1인 구매수량을 제한한 쿠팡상품(특히 로켓상품)의 경우 제한한 수량보다 재고가 큰 경우 판매량이 나오지 않을 수 있습니다.",
			desc3:"재고 변화량 = 판매량이므로 재고가 늘어나는 경우 마이너스 판매량이 나올 수 있습니다. (판매가가 0인경우 : 판매중지 상품)",
			link:{ name: 'people.addusers'},
			linktext:"판매현황 수집",

			SKUID:'',
			showspinner: false,
			startdate: this.getYesterday(),
			enddate: this.getTodaywith(),
			saleslists: [],
			saleslistsinit: [],
			showlist: false,

			maxrows : 200,
			minuslimit : -2,
			applyminuslimit : false,

			intervaltime: 60,
			currentTime: '01:00:00',
			countdownRunning: false,
			countdownInterval: null,

			UserListS:[
				{
					name:'Cliff Hanger',
					email:'cliff@gmail.com',
					company:'Product Manager', 
					group:'Cliff',
					status:'Active',
					store:'1'
				},
				{
					name:'Terry Aki',
					email:'terry@gmail.com',
					company:'Stock CEO', 
					group:'Terry',
					status:'Active',
					store:'2'
				}
			]
		}
	},
	created(){
        document.addEventListener.call(window, "message", event => {
            if(event.data.res && event.data.res === 'tracking'){
                if(!event.data.msg.includes('수집완료')){
					this.loadingtext = '데이터 불러오는 중 ...';
					alert(event.data.msg)
					this.showspinner = false;
					this.showlist = true;					
				} else {
					this.search()
					if(this.timerRunning){
						this.timerCnt++
						this.stopCountdown()
						this.startCountdown()	
					}
				}
            }
            if(event.data.res && event.data.res === 'trackingratio'){
                this.loadingtext = '판매량 수집 중 ... ' + parseInt(event.data.ratio*100) + '%';
				
            }			
        });
    },	
    mounted() {
        this.$nextTick(function () {
            this.search()      
        })
		window.addEventListener('beforeunload', this.confirmExit);
    },
	beforeDestroy(){
		window.removeEventListener('beforeunload', this.confirmExit);
	},
	methods: {
		confirmExit(event) {
			if(this.timerRunning){
				event.preventDefault();
				event.returnValue = '페이지를 벗어날 경우 자동수집이 종료됩니다. 그래도 벗어나시겠습니까?';
				return '페이지를 벗어날 경우 자동수집이 종료됩니다. 그래도 벗어나시겠습니까?';
			}
		},
		startCountdown() {
			this.countdownRunning = true;
			this.countdownInterval = setInterval(() => {
				this.updateTime();
			}, 1000);
		},
		updateTime() {
			const timeArray = this.currentTime.split(':');
			let hours = parseInt(timeArray[0]);
			let minutes = parseInt(timeArray[1]);
			let seconds = parseInt(timeArray[2]);

			if (hours === 0 && minutes === 0 && seconds === 0) {
				this.stopCountdown();
			} else {
				seconds--;
				if (seconds < 0) {
				seconds = 59;
				minutes--;
				if (minutes < 0) {
					minutes = 59;
					hours--;
					if (hours < 0) {
					hours = 0;
					}
				}
				}
				this.currentTime = this.formatTime(hours) + ':' + this.formatTime(minutes) + ':' + this.formatTime(seconds);
			}
		},
		stopCountdown() {
			this.countdownRunning = false;
			clearInterval(this.countdownInterval);
		},
		formatTime(value) {
			return value.toString().padStart(2, '0');
		},
		startTimer() {
			this.timerRunning = true;
			var d = new Date();
			var hour = d.getHours(); // 시, 10
			var min = d.getMinutes(); // 분, 35
			var sec = d.getSeconds(); // 초, 42
			this.startTime = this.formatTime(hour) + ":" + this.formatTime(min) + ":" + this.formatTime(sec);
			this.timerCnt = 0;
			this.collecttr(); // 최초 실행
			
			this.timerInterval = setInterval(() => {
				this.stopCountdown()
				this.startCountdown()
				this.collecttr();
			}, 3600000); // 1시간(60분 * 60초 * 1000밀리초)
		},
		stopTimer() {
			this.timerRunning = false;
			clearInterval(this.timerInterval);
			this.stopCountdown()
			this.currentTime = '01:00:00';
		},
		applycorrect(){
			if(this.data.length > 0){
				this.showspinner = true;
				this.showlist = false;				
				this.searchresult()
			} else {
				alert('추가된 모니터링 상품이 없습니다.')
				return
			}
		},
		checkalllist(){
			if(this.checkalllists){
				this.saleslists.forEach(e => {
					e.checked = true
				})
			} else {
				this.saleslists.forEach(e => {
					e.checked = false
				})
			}
		},
		deleteitem(){
			if(this.saleslists.filter(e => e.checked).length == 0){
				alert('삭제할 상품을 선택해 주세요')
			} else {
				var arr = this.saleslists.filter(e => e.checked)
				if(confirm(arr.length + '개의 상품을 삭제하시겠습니까?')){
					axios.post('/api/analysis/deletetracking',arr)
					.then(result => {
						if(result.data.res == 'OK'){
							this.search()
						} else {
							alert('삭제실패')
							console.log(result)
						}
					})
				}
			}
		},
		collecttr(){
			window.postMessage({greeting: "collecttracking", companyid:this.$store.state.user.companyid},"*",)
			this.showspinner = true;
			this.showlist = false;
		},
		modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
		salesdownload(){
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			const workBook = XLSX.utils.book_new()
			var obj = {};
			var arr = [];
			this.saleslists.forEach(e => {
				arr = [];
				e.판매세부내역.forEach(ele => {
					obj = {
						상품명 : e.판매상품명,
						Date : ele.date,
						재고 : ele.재고,
						판매 : ele.판매,
						판매가 : ele.판매가,
						판매매출 : ele.판매매출,
						리뷰수 : ele.리뷰수,
						리뷰평점 : ele.리뷰평점
					}
					arr.push(obj)
				})
				var workSheet = XLSX.utils.json_to_sheet(arr)
				XLSX.utils.book_append_sheet(workBook, workSheet, e.vendorItemId.toString())
			})

			
			XLSX.writeFile(workBook, '일자별판매현황.xlsx')
		},
		search(){
			this.showspinner = true;
			this.showlist = false;
			axios.post('/api/analysis/gettracking',{companyid:this.$store.state.user.companyid})
			.then(result => {
                if(result.data.length > 0){
                    this.data = result.data;
					console.log(result.data)
                    this.searchresult()
                } else {
                    alert('추가된 모니터링 상품이 없습니다.')
                    return
                }
			})
		},
		datetotimestamp(a){
			var date = new Date(a);
			return date.getTime();
		},
        searchresult(){
            this.saleslists = [];
            this.saleslistsinit = [];
            var obj = {}
            var arr = [];
            this.data.forEach(e => {
				if(e.type == 'wing'){
					var 로켓구분 = '마켓플레이스';
				} else {
					if(e.type == 'rocket'){
						로켓구분 = '로켓배송';
					} else {
						로켓구분 = '로켓그로스';
					}
				}
				if(!e.productinfo.images){
					console.log(e)
				}
                obj = {
					checked : false,
                    썸네일 : e.productinfo.images ? 'https:' + e.productinfo.images[0].origin : '',
                    판매상품명 : e.productinfo.title + ', ' + e.optionName,
                    productId : e.productId,
					vendorItemId : e.vendorItemId,
					로켓구분 : 로켓구분,
					로켓뱃지 : e.productinfo.quantityBase ? e.productinfo.quantityBase[0].delivery.badgeUrl : '',
					공급자 : e.vendorName,
					판매자링크 : 'https://store.coupang.com/vp/vendors/' + e.vendorId + '/products',
                    재고 : 0,
                    판매 : 0,
                    판매가 : 0,
                    판매매출 : 0,
                    리뷰수 : 0,
                    리뷰평점 : 0,
                    판매링크 : 'https://www.coupang.com/vp/products/' + e.productId + '?itemId=' + e.itemId + '&vendorItemId=' + e.vendorItemId,
                    판매세부내역 : []
                }


				var detail = {};
				var ar = [];
				var prevstock = 0;
				var first = true
				e.list.forEach(ele => {
					if(first){
						detail = {
							date : this.timestamptodate(ele.d),
							재고 : ele.s,
							판매 : 0,
							판매가 : ele.p,
							판매매출 : 0,
							리뷰수 : ele.rq,
							리뷰평점 : ele.rp,
							뱃지 : ele.b,
						}
						obj.재고 = detail.재고;
						obj.판매 += detail.판매;
						obj.판매가 = detail.판매가;
						obj.판매매출 += detail.판매매출;
						obj.리뷰수 = detail.리뷰수;
						obj.리뷰평점 = detail.리뷰평점;
						ar.unshift(this.deepClone(detail))
						first = false
					} else {
						if(this.applyminuslimit){
							if(prevstock-ele.s <= this.minuslimit){
								prevstock = ele.s
							}
						}
						detail = {
							date : this.timestamptodate(ele.d),
							재고 : ele.s,
							판매 : prevstock-ele.s,
							판매가 : ele.p,
							판매매출 : (prevstock-ele.s)*ele.p,
							리뷰수 : ele.rq,
							리뷰평점 : ele.rp,
							뱃지 : ele.b,
						}
						obj.재고 = detail.재고;
						obj.판매 += detail.판매;
						obj.판매가 = detail.판매가;
						obj.판매매출 += detail.판매매출;
						obj.리뷰수 = detail.리뷰수;
						obj.리뷰평점 = detail.리뷰평점;
						ar.unshift(this.deepClone(detail))
					}
					prevstock = detail.재고;
				})
				if(e.list.length > 0){
					if(((e.list[e.list.length-1].d - e.list[0].d)/(1000*60*60*24)) < 1){
						obj.일평균판매량 = parseFloat(obj.판매);
						obj.일평균매출 = parseFloat(obj.판매*obj.판매가);
					} else {
						obj.일평균판매량 = parseFloat(obj.판매/((e.list[e.list.length-1].d - e.list[0].d)/(1000*60*60*24)));
						obj.일평균매출 = parseFloat(obj.일평균판매량*obj.판매가);
					}
				} else {
					obj.일평균판매량 = 0;
					obj.일평균매출 = 0;
				}

				obj.판매세부내역 = ar;
                arr.push(obj)
            })
            arr.forEach(e => {
                this.saleslists.push(this.deepClone(e))
                this.saleslistsinit.push(this.deepClone(e))
            })

            if(this.saleslists.length == this.data.length){
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 10);
            }
			console.log(this.saleslists)
            
        },
		timestamptodate(timestamp){
			var d = new Date(timestamp + (1000*60*60*9));
			return d.toISOString().replace('T',' ').slice(0,19)
		},
		searchresult2(){
			this.saleslists = [];
			this.salesarr = [];
			this.saleslistsinit = [];
			var obj = {};
			var d = {};
			// var 이전재고 = '';
			// var t = {};
			// var su = {};
			// var 입고수량 = 0;
			var sales = [];
			this.$store.state.sales.forEach(e => {
				sales.push(this.deepClone(e))
			})
			var supply = [];
			this.$store.state.supply.forEach(e => {
				supply.push(this.deepClone(e))
			})
			var productdb = this.$store.state.productdb.filter(e => !e.등록이미지?.includes('vendor_inventory'));
			if(this.$store.state.sales.length > 0){
				console.log(this.$store.state.sales)
				console.log(this.$store.state.supply)
				var dates = [];
				sales.forEach(e => {
					if(!dates.find(ele => ele.date == e.date)){
						dates.push({date : e.date})
					}
				})
				supply.forEach(e => {
					if(!dates.find(ele => ele.date == e.importdate) && dates[0].date < e.importdate){
						dates.push({date : e.importdate})
					}
				})
				// console.log(dates)
				dates.sort(function(a,b){
					if (a.date > b.date){
						return 1;
					}
					if (a.date < b.date){
						return -1;
					}
					return 0;
				})
				productdb.sort(function(a,b){
					if (a["SKU ID"] > b["SKU ID"]){
						return 1;
					}
					if (a["SKU ID"] < b["SKU ID"]){
						return -1;
					}
					return 0;
				})
				sales.forEach(e => {
					e.판매세부내역.sort(function(a,b){
						if (a.sk > b.sk){
							return 1;
						}
						if (a.sk < b.sk){
							return -1;
						}
						return 0;
					})
				})
				supply.forEach(e => {
					e.importlist.sort(function(a,b){
						if (a.sku번호 > b.sku번호){
							return 1;
						}
						if (a.sku번호 < b.sku번호){
							return -1;
						}
						return 0;
					})
				})

				

				for(var i=0;i<productdb.length;i++){
					var pred = {
						재고 : 0,
						입고 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매가 : 0,
					}
					obj = {
						썸네일 : productdb[i].썸네일,
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드,
						발주가능상태 : productdb[i].발주가능상태,
						재고 : 0,
						판매 : 0,
						입고 : 0,
						판매가 : 0,
						판매매출 : 0,
						공급매출 : 0,
						리뷰수 : 0,
						리뷰평점 : 0,
						판매링크 : productdb[i].판매링크,
						판매세부내역 : []
					}
					var obsales = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obstock = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}
					var obimport = {
						판매상품명 : productdb[i].판매상품명,
						SKUID : productdb[i]["SKU ID"],
						바코드 : productdb[i].바코드
					}										
					dates.forEach((e,k) => {

						d = {
							date : e.date,
							발주가능상태 : productdb[i].발주가능상태,
							재고 : 0,
							판매 : 0,
							입고 : 0,
							판매가 : 0,
							판매매출 : 0,
							공급매출 : 0,
							리뷰수 : 0,
							리뷰평점 : 0,
							판매링크 : productdb[i].판매링크,
							재고확인시간 : 0,
						}
						// console.log(e)
						// console.log(sales.findIndex(ele => ele.date == e.date))
						if(sales.findIndex(ele => ele.date == e.date) >= 0){
							var j = sales.findIndex(ele => ele.date == e.date)
							// console.log(j)
							for(var n=0;n<sales[j].판매세부내역.length;n++){
								
								if(parseInt(sales[j].판매세부내역[n].sk) == parseInt(productdb[i]["SKU ID"])){
									if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
										console.log(e)
										console.log(sales[j].판매세부내역[n])
									}
									d.재고 = sales[j].판매세부내역[n].s === 0 || sales[j].판매세부내역[n].s > 0 ? parseInt(sales[j].판매세부내역[n].s) : sales[j].판매세부내역[n].s < 0 ? 0 : pred.재고;
									d.리뷰수 = sales[j].판매세부내역[n].rq === 0 || sales[j].판매세부내역[n].rq ? parseInt(sales[j].판매세부내역[n].rq) : pred.리뷰수;
									d.리뷰평점 = sales[j].판매세부내역[n].rp === 0 || sales[j].판매세부내역[n].rp ? parseFloat(sales[j].판매세부내역[n].rp) : pred.리뷰평점;
									d.판매가 = sales[j].판매세부내역[n].p === 0 || sales[j].판매세부내역[n].p ? parseFloat(sales[j].판매세부내역[n].p) : pred.판매가;
									d.재고확인시간 = sales[j].time ? sales[j].time : 0;
									sales[j].판매세부내역.splice(n,1)
									// console.log(sales[j].판매세부내역.length)
									break;
								}
								if(parseInt(sales[j].판매세부내역[n].sk) > parseInt(productdb[i]["SKU ID"])){
									d.재고 = pred.재고;
									d.리뷰수 = pred.리뷰수;
									d.리뷰평점 = pred.리뷰평점;
									d.판매가 = pred.판매가;
									break;
								}
							}
						} else {
							d.재고 = pred.재고;
							d.리뷰수 = pred.리뷰수;
							d.리뷰평점 = pred.리뷰평점;
							d.판매가 = pred.판매가;
						}
						// if(productdb[i]["SKU ID"] === 18462723){
						// 	console.log(d.재고)
						// }
						if(supply.findIndex(ele => ele.importdate == e.date) >= 0){
							j = supply.findIndex(ele => ele.importdate == e.date)
							for(n=0;n<supply[j].importlist.length;n++){
								// console.log(supply[j].importlist[n])
								// console.log(supply[j].importlist[n].sku번호)
								if(parseInt(supply[j].importlist[n].sku번호) == parseInt(productdb[i]["SKU ID"])){
									// if(productdb[i]["SKU ID"] === 17064982 || productdb[i]["SKU ID"] ===  27573440){
									// 	console.log(e)
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// 	console.log(d.재고확인시간)
									// 	console.log(this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// 	console.log(d.재고확인시간 < this.datetotimestamp(supply[j].importlist[n]['입고/반출일자']))
									// }
									d.입고 += parseInt(supply[j].importlist[n].수량);
									if(d.재고확인시간 <= this.datetotimestamp(supply[j].importlist[n]['입고/반출일자'])){
										d.재고 += parseInt(supply[j].importlist[n].수량);
									}
									// if(productdb[i]["SKU ID"] === 18462723){
									// 	console.log(supply[j].importlist[n])
									// 	console.log(d.재고)
									// }
								}
								if(parseInt(supply[j].importlist[n].sku번호) > parseInt(productdb[i]["SKU ID"])){
									// d.입고 = pred.입고;
									// d.재고 = pred.재고;
									break;
								}
							}
						}
						if(k == 0){
							d.판매 = 0;
						} else {
							d.판매 = pred.재고 - d.재고 + d.입고;
						}
						d.판매매출 = d.판매가 * d.판매;
						d.공급매출 = productdb[i].매입가 ? productdb[i].매입가 * d.판매 : productdb[i].현재매입가 ? productdb[i].현재매입가 * d.판매 : 0;
						pred = d;
						obj.판매세부내역.push(d)

						obj.재고 = d.재고;
						obj.판매 += d.판매;
						obj.입고 += d.입고;
						obj.판매매출 += d.판매매출;
						obj.공급매출 += d.공급매출;
						obj.판매가 = d.판매가;
						obj.리뷰수 = d.리뷰수;
						obj.리뷰평점 = d.리뷰평점;

						obsales[e.date] = d.판매;
						obimport[e.date] = d.입고;
						obstock[e.date] = d.재고;
					})
					this.salesarr.push(obsales);
					this.stockarr.push(obstock);
					this.importarr.push(obimport);

					this.saleslists.push(this.deepClone(obj))
					this.saleslistsinit.push(this.deepClone(obj))
				}
				
				
				this.salescnt = this.saleslists.reduce((pv,rv) => { return pv + rv.판매},0)
				this.importcnt = this.saleslists.reduce((pv,rv) => { return pv + rv.입고},0)
				this.salesamount = this.saleslists.reduce((pv,rv) => { return pv + rv.판매매출},0)
				this.supplyamount = this.saleslists.reduce((pv,rv) => { return pv + rv.공급매출},0)
				this.noreview = this.saleslists.filter(e => e.리뷰수 == 0).length;
				this.productqty = this.saleslists.length;
				this.nostock = this.saleslists.filter(e => e.재고 == 0).length;

				if(this.saleslists.length == 0){
					alert("판매현황이 없습니다.")
					this.showspinner = false;
					return
				}
				setTimeout(() => {
					this.showlist = true;
					this.showspinner = false;
				}, 1);
			} else {
				alert("판매현황이 없습니다.")
				this.showspinner = false;
				return
			}			
			console.log(this.salesarr)
		},
		
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getDatewith(d){
			var date = new Date(d);
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},
		getYesterday(){
			return this.getDatewith(this.datetotimestamp(this.getTodaywith()) - 1000*60*60*24)
		},
		downloadsales() {
			if(this.saleslists.length == 0){
				alert('다운로드할 내역이 없습니다.')
				return
			}
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}

			var arr = [];
			var obj = {};
			this.saleslists.forEach(e => {
				obj = {
					상품명 : e.판매상품명,
					로켓구분 : e.로켓구분,
					공급자 : e.공급자,
					재고 : e.재고,
					판매 : e.판매,
					판매가 : e.판매가,
					판매매출 : e.판매매출,
					리뷰수 : e.리뷰수,
					리뷰평점 : e.리뷰평점,
					판매링크 : e.판매링크,
				}
				arr.push(obj)
			})


			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '경쟁사판매현황')
			XLSX.writeFile(workBook, '경쟁사판매현황' + '_' + getToday() + '.xlsx')
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
.modal-dialog {
    max-width: 90%;
    position: absolute;
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
#salestable {
  overflow-y: auto;
}

#salestable tbody {
  display: block;
  height: 400px; /* 테이블의 높이 지정 */
  overflow-y: scroll;
  width:100%
}

#salestable thead,
#salestable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}


#salestable tbody {
  width: 100%;
  overflow-y: auto;
}

#salestable thead th,
#salestable tbody td {
	width: 11.11%;
}


</style>